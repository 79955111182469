import React from 'react';

export const RecoveryVideo = () => {
	return (
		<>
			<h1 className="mt-6">Dr Simeonsi dieedi etapp 1 – kosumine</h1>

			<iframe
				className={"md:hidden w-full aspect-[4/3]"}
				src="https://www.youtube.com/embed/2rIwhsTj6Nk?time_continue=1&feature=emb_logo"
				title="YouTube video player"
				frameBorder="0"
				allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
				allowFullScreen
			/>

			<iframe
				className={"hidden md:block lg:hidden w-full aspect-[4/3]"}
				src="https://www.youtube.com/embed/2rIwhsTj6Nk?time_continue=1&feature=emb_logo"
				height={"400"}
				title="YouTube video player"
				frameBorder="0"
				allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
				allowFullScreen
			/>

			<iframe
				className={"hidden md:hidden lg:block w-full aspect-[4/3]"}
				src="https://www.youtube.com/embed/2rIwhsTj6Nk?time_continue=1&feature=emb_logo"
				height={"560"}
				title="YouTube video player"
				frameBorder="0"
				allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
				allowFullScreen
			/>
		</>
	)
}