import React from "react";

import Section from "../../Section";

import {Videos} from "../components/videos";

export const GuideVideos = () => {

	return (
		<Section title="Tutvustavad lühivideod">
			<Videos/>
		</Section>
	)
}