import React, {useEffect, useState} from "react";

import api from "../../utils/api";

import {useCookies} from "react-cookie";
import {Viewer, Worker, SpecialZoomLevel} from "@react-pdf-viewer/core";
import {zoomPlugin} from "@react-pdf-viewer/zoom";

import {useQueryParam, StringParam} from "use-query-params";

import Layout from "../../components/Layout";
import Section from "../../components/Section";
import Promo from "../../components/Promo";
import Card from "../../components/Card";
import {GuideVideos} from "../../components/Guide/Sections/guide-videos";

import backIcon from "../../images/back.png";
import ZoomInPdf from "../../images/zoom-in.svg";
import ZoomOutPdf from "../../images/zoom-out.svg";

import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";
import "@react-pdf-viewer/print/lib/styles/index.css";
import "@react-pdf-viewer/zoom/lib/styles/index.css";


export default function Guide(props) {
	const [pdfQueryParam, setPdfQueryParam] = useQueryParam("pdf", StringParam);
	const [textQueryParam, setTextQueryParam] = useQueryParam(
		"text",
		StringParam
	);

	const [guides, setGuides] = useState([]);
	const [pdf, setPdf] = useState(pdfQueryParam ?? null);
	const [pdfTitle, setPdfTitle] = useState(
		textQueryParam ?? "Dr Simeonsi dieediprogrammi juhend"
	);
	const [cookies] = useCookies(["token", "id"]);
	const [token] = useState(cookies["token"]);
	const [idUser] = useState(cookies["id"]);

	const zoomPluginInstance = zoomPlugin();
	const {ZoomIn, ZoomOut} = zoomPluginInstance;

	useEffect(() => {
		api.getUserGuides(token, {id: idUser}).then((res) => {
			setGuides(res.files);
		});
	}, [idUser, token]);

	function setCurrentPdf(link, title) {
		setPdf(link);
		setPdfTitle(title);
	}

	function handleBackClick() {
		setPdf(null);
		setPdfTitle("Dr Simeonsi dieediprogrammi juhend");
		setPdfQueryParam(null);
		setTextQueryParam(null);
	}

	return (
		<Layout location={props.location}>
			<Section title={pdfTitle}>
				{pdf === null ? (
					<ul className={"mt-11 md:w-11/12"}>
						{guides.map((item) => {
							return (
								<Card
									guidePage={true}
									key={item.displayName}
									setCurrentPdf={setCurrentPdf}
									target={"_blank"}
									title={item.displayName}
									link={item.filePath}
								/>
							);
						})}
					</ul>
				) : (
					<div className={"max-w-full"}>
						<div className={"flex justify-between mb-3"}>
							<button onClick={handleBackClick} className={"flex items-center"}>
								<img src={backIcon} className={"w-3 h-3"} alt={"back-icon"}/>
								<p className={"text-sm text-green ml-1 uppercase"}>tagasi</p>
							</button>
							<div className={"flex m-0"}>
								<ZoomIn>
									{(props) => (
										<button
											className={"text-green flex self-center ml-4"}
											onClick={props.onClick}
										>
											<img src={ZoomInPdf} alt={"zoom-in-icon"}/>
											<p className={"text-sm ml-1 hidden md:block"}>
												Suumi sisse
											</p>
										</button>
									)}
								</ZoomIn>

								<ZoomOut>
									{(props) => (
										<button
											className={"text-green flex self-center ml-4"}
											onClick={props.onClick}
										>
											<img src={ZoomOutPdf} alt={"zoom-out-icon"}/>
											<p className={"text-sm ml-1 hidden md:block"}>
												Suumi välja
											</p>
										</button>
									)}
								</ZoomOut>
							</div>
						</div>
						<Worker workerUrl="https://unpkg.com/pdfjs-dist@2.12.313/build/pdf.worker.js">
							<div className={"w-72 sm:w-649 md:w-700 lg:w-900 h-750 m-auto"}>
								<Viewer
									fileUrl={pdf}
									defaultScale={SpecialZoomLevel.PageWidth}
									plugins={[zoomPluginInstance]}
								/>
							</div>
						</Worker>
					</div>
				)}
			</Section>

			<GuideVideos/>

			<Promo/>
		</Layout>
	);
}
