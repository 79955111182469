import React from 'react';

import {RecoveryVideo} from "./recovery-video";
import {KcalDieet} from "./kcal-dieet";
import {UnloadingStabilizationVideo} from "./unloading-stabilization-video";

export const Videos = () => {
	return (
		<>
			<RecoveryVideo/>

			<KcalDieet/>

			<UnloadingStabilizationVideo/>
		</>
	)
}